import { createApp } from 'vue'
import App from './App.vue'
import { Button, NavBar, Form, Empty, Field, CellGroup, Toast, RadioGroup, Radio, DatePicker, TimePicker, Popup, Picker, Card, Tab, Tabs, Icon, DropdownMenu, DropdownItem, Dialog, Popover, Signature } from 'vant';
import { createI18n } from "vue-i18n";
import { Image as VanImage } from 'vant';
import 'vant/lib/index.css';
import router from './router/index.js';
import zh from "./language/zh-TW.json";
import en from "./language/en-US.json";

const i18n = createI18n({
    legacy: false,
    locale: localStorage.getItem("locale") ? localStorage.getItem("locale") : "zh-TW",
    fallbackLocale: "zh-TW",
    messages: {
        "zh-TW": zh,
        "en-US": en,
    }
});
createApp(App)
    .use(Button)
    .use(NavBar)
    .use(Form)
    .use(Field)
    .use(CellGroup)
    .use(Radio)
    .use(RadioGroup)
    .use(DatePicker)
    .use(TimePicker)
    .use(Popup)
    .use(Picker)
    .use(Card)
    .use(Tab)
    .use(Tabs)
    .use(Icon)
    .use(DropdownMenu)
    .use(DropdownItem)
    .use(Dialog)
    .use(Popover)
    .use(Signature)
    .use(Toast)
    .use(Empty)
    .use(VanImage)
    .use(router)
    .use(i18n)
    .mount('#app')